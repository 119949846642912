<template>
    <div v-if="notEmpty">
        <slot></slot>
        <div v-if="pronoun && pronoun.sourcesInfo" class="alert alert-info small">
            <Icon v="info-circle" />
            <LinkedText :text="pronoun.sourcesInfo" />
        </div>
        <SourcesChart :sources="sources" :label="pronoun ? pronoun.name() : ''" />
        <ul class="list-unstyled">
            <li v-for="source in visibleSources" :key="`${source.id}-${listId}`" class="my-2 clearfix">
                <Source :source="source" :manage="manage" @edit-source="edit" />
            </li>
        </ul>
    </div>
</template>

<script>
import { makeId } from '../src/helpers.ts';

export default {
    props: {
        sources: { required: true },
        pronoun: { },
        filter: { default: '' },
        filterType: { default: '' },
        manage: { type: Boolean },
    },
    emits: ['edit-source'],
    data() {
        const sourcesMap = {};
        for (const source of this.sources) {
            sourcesMap[source.id] = source;
        }

        return {
            listId: makeId(6),
            sourcesUnique: Object.values(sourcesMap),
        };
    },
    computed: {
        visibleSources() {
            return this.sourcesUnique.filter(this.isVisible);
        },
        notEmpty() {
            return this.visibleSources.length > 0;
        },
    },
    methods: {
        isVisible(source) {
            if (this.filterType && this.filterType !== source.type) {
                return false;
            }

            if (this.filter) {
                return source.index.includes(this.filter.toLowerCase());
            }

            return true;
        },
        edit(source) {
            // TODO it should be possible to do it nicer
            this.$emit('edit-source', source);
        },
    },
};
</script>
